import { mapActions, mapGetters } from 'vuex'
import _get from 'lodash/get'
import _find from 'lodash/find'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import { isMobile } from '@platform-shared/utils/device'

import {
  QUESTIONNAIRE_TYPES,
  QUESTIONNAIRE_STATUS,
  HEALTH_ACTION_CODES,
} from '@platform-shared/constants'

export default {
  computed: {
    ...mapGetters('healthActions', [
      'completedHealthActions',
      'healthActionById',
    ]),
    ...mapGetters('questionnaire', ['questionnaireByType', 'isLoading']),
    questionnaireToDo() {
      return this.questionnaireByType(this.questionnaireType)
    },
    questionnaireToDoStatus() {
      return (
        _get(this.questionnaireToDo, 'status') ||
        QUESTIONNAIRE_STATUS.NOT_STARTED
      )
    },
    questionnaireToDoStarted() {
      return this.questionnaireToDoStatus === QUESTIONNAIRE_STATUS.STARTED
    },
    questionnaireToDoHasPreviousCompletion() {
      return _get(this.questionnaireToDo, 'allCompletions', []).length > 0
    },
    questionnaireToDoLastTaken() {
      const date = _get(this.questionnaireToDo, 'lastCompleted')
      return date ? format(parseISO(date), 'MM/dd/yyyy') : date
    },
    questionnaireToDoIsComplete() {
      return !!_find(this.completedHealthActions, {
        code: this.healthActionCode,
      })
    },
    healthActionId() {
      return isMobile ? _get(this.action, 'id') : this.$route.params.id
    },
    healthAction() {
      return this.healthActionById(this.healthActionId)
    },
    healthActionCode() {
      return _get(this.healthAction, 'code')
    },
    questionnaireType() {
      switch (this.healthActionCode) {
        case HEALTH_ACTION_CODES.HEALTH_PLAN_FEEDBACK_SURVEY:
          return QUESTIONNAIRE_TYPES.MOCK_CAHPS
        case HEALTH_ACTION_CODES.MCD_ADULT_HEALTH_PLAN_FEEDBACK_SURVEY:
          return QUESTIONNAIRE_TYPES.MEDICAID_ADULT_MOCK_CAHPS
        case HEALTH_ACTION_CODES.MCD_YOUTH_HEALTH_PLAN_FEEDBACK_SURVEY:
          return QUESTIONNAIRE_TYPES.MEDICAID_CHILD_MOCK_CAHPS
        case HEALTH_ACTION_CODES.PROVIDER_SURVEY:
          return QUESTIONNAIRE_TYPES.PROVIDER
        case HEALTH_ACTION_CODES.SNS_SURVEY:
          return QUESTIONNAIRE_TYPES.SNS
        case HEALTH_ACTION_CODES.FMC:
          return QUESTIONNAIRE_TYPES.FMC
        case HEALTH_ACTION_CODES.PATIENT_ENGAGEMENT_SURVEY:
          return QUESTIONNAIRE_TYPES.PATIENT_ENGAGEMENT
      }
    },
    questionnaireToDoQuestionnaireUrl() {
      return `/questionnaire/${this.questionnaireType}`
    },
  },
  methods: {
    ...mapActions('questionnaire', ['getQuestionnairesByType']),
  },
  mounted() {
    if (!this.questionnaire && !this.isLoading) {
      this.getQuestionnairesByType(this.questionnaireType)
    }
  },
}
