import { mapGetters, mapActions } from 'vuex'
import _get from 'lodash/get'
import _sumBy from 'lodash/sumBy'
import _filter from 'lodash/filter'
import _find from 'lodash/find'
import {
  HEALTH_ACTION_CODES,
  HEALTH_ACTION_TYPES,
  FEATURES,
  QUESTIONNAIRE_STATUS,
  QUESTIONNAIRE_TYPES,
  HEALTH_ACTION_GROUP_IDS,
} from '@platform-shared/constants'
import flow from 'lodash/fp/flow'
import getOr from 'lodash/fp/getOr'
import orderBy from 'lodash/fp/orderBy'
import head from 'lodash/fp/head'
import map from 'lodash/fp/map'
import join from 'lodash/fp/join'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import isAfter from 'date-fns/isAfter'
import isThisYear from 'date-fns/isThisYear'
import hraMixin from './hraMixin.js'
import questionnaireToDoMixin from './questionnaireToDoMixin.js'
import localizeMixin from '../localizeMixin.js'
import { isWithinDateInterval } from '@platform-shared/utils/dateHelpers'
import { isMobile } from '@platform-shared/utils/device'
import { addDays } from 'date-fns'

export default {
  props: {
    overrideHealthActionId: {
      type: [String, Number],
      required: false,
      default: undefined,
    },
  },
  created() {
    if (this.hasFeature(FEATURES.REWARDS)) {
      this.initRewards()
    }

    this.initQuestionnaires()
    this.initHealthActions()

    if (this.hasFeature(FEATURES.EDUCATION)) {
      this.initLearn()
    }

    if (this.hasFeature(FEATURES.TRACKING)) {
      this.initTracking()
    }
  },
  mixins: [hraMixin, questionnaireToDoMixin, localizeMixin],
  data() {
    const currentYear = new Date().getFullYear()
    return {
      mrosQuestionnaires: [
        {
          name: "Doctor's Visit",
          type: QUESTIONNAIRE_TYPES.MROS_DR_APPT,
          availableOn: new Date(`${currentYear}/01/01`),
        },
        {
          name: 'Mental Health',
          type: QUESTIONNAIRE_TYPES.MROS_MENTAL_HEALTH,
          availableOn: new Date(`${currentYear}/03/01`),
        },
        {
          name: 'Physical Health',
          type: QUESTIONNAIRE_TYPES.MROS_PHYSICAL_HEALTH,
          availableOn: new Date(`${currentYear}/06/01`),
        },
      ],
    }
  },
  computed: {
    ...mapGetters('client', ['hasFeature']),
    ...mapGetters('member', [
      'memberCoverageInfo',
      'showDependents',
      'getActivityByType',
    ]),
    ...mapGetters('healthActions', [
      'healthActionsLoading',
      'healthActionById',
      'healthActionByCode',
      'lastCompletionById',
      'isEarnedByAction',
    ]),
    ...mapGetters('learn', [
      'historicalArticleCount',
      'historicalArticles',
      'learnInitLoading',
    ]),
    ...mapGetters('questionnaire', ['isLoading', 'questionnaireByType']),
    ...mapGetters('rewards', [
      'currentRewardsSummary',
      'currentStrategyRewardsHistory',
      'currentFamilyRewardHistory',
      'earnedAmountByHealthActionCd',
    ]),

    // health action info
    healthAction() {
      const id = this.overrideHealthActionId
        ? this.overrideHealthActionId
        : isMobile
        ? _get(this.action, 'id')
        : this.$route.params.id

      switch (id) {
        case 'hra':
          return this.healthActionByCode(
            HEALTH_ACTION_CODES.COMPLETE_HEALTH_QUESTIONNAIRE
          )
        default:
          return this.healthActionById(id)
      }
    },
    healthActionId() {
      return _get(this.healthAction, 'id')
    },
    healthActionCode() {
      return _get(this.healthAction, 'code')
    },

    // special types
    isEducationBundle() {
      return this.healthActionCode === HEALTH_ACTION_CODES.EDUCATION_BUNDLE
    },
    isMrosSurvey() {
      return (
        this.healthActionCode ===
        HEALTH_ACTION_CODES.MEMBER_RECORDED_OUTCOMES_SURVEY
      )
    },
    isHra() {
      return (
        this.healthActionCode ===
        HEALTH_ACTION_CODES.COMPLETE_HEALTH_QUESTIONNAIRE
      )
    },
    usesHraSatisfactionSurvey() {
      return this.hasFeature(FEATURES.HRA_SAT)
    },
    isQuestionnaireToDo() {
      return [
        HEALTH_ACTION_CODES.HEALTH_PLAN_FEEDBACK_SURVEY,
        HEALTH_ACTION_CODES.MCD_ADULT_HEALTH_PLAN_FEEDBACK_SURVEY,
        HEALTH_ACTION_CODES.MCD_YOUTH_HEALTH_PLAN_FEEDBACK_SURVEY,
        HEALTH_ACTION_CODES.PROVIDER_SURVEY,
        HEALTH_ACTION_CODES.SNS_SURVEY,
        HEALTH_ACTION_CODES.FMC,
        HEALTH_ACTION_CODES.PATIENT_ENGAGEMENT_SURVEY,
      ].includes(this.healthActionCode)
    },
    isProfileUpdate() {
      return this.healthActionCode === HEALTH_ACTION_CODES.PROFILE_UPDATE
    },
    isComplex() {
      return (
        this.healthAction.healthActionTypeCd === HEALTH_ACTION_TYPES.COMPLEX
      )
    },
    isLearnAndEarn() {
      return !!_find(this.healthAction.groups, {
        id: HEALTH_ACTION_GROUP_IDS.LEARN_AND_EARN,
      })
    },

    // helpers
    currentCompliancePeriod() {
      return _get(this.memberCoverageInfo, 'currentCompliancePeriod')
    },
    instanceStartDate() {
      return _get(this.healthAction, 'instanceStartDate')
    },
    instanceEndDate() {
      return _get(this.healthAction, 'instanceEndDate')
    },
    attestationConfig() {
      return _get(this.healthAction, 'attestationConfig', {})
    },
    supportsMemberAttestation() {
      return _get(this.healthAction, 'supportsMemberAttestation', false)
    },
    isRewardedThisCompliancePeriod() {
      return this.isEarnedByAction(this.healthAction)
    },
    isComplete() {
      return this.toDoMembers.length === 0
    },
    lastCompletion() {
      if (this.isQuestionnaireToDo) {
        return this.questionnaireToDoLastTaken
      }

      const lastCompletion = this.lastCompletionById(this.healthActionId)
      return lastCompletion
        ? format(parseISO(lastCompletion), 'MM/dd/yyyy')
        : ''
    },
    title() {
      return _get(this.healthAction, 'title')
    },
    adjustedTitle() {
      return this.title.replace(/Complete/, '')
    },
    longDescription() {
      return _get(this.healthAction, 'longDescription')
    },
    incentiveCatalogCd() {
      return _get(this.healthAction, 'incentiveCatalogCd')
    },
    incentiveAmount() {
      if (this.isComplex && this.isRewardedThisCompliancePeriod) {
        const rewardsForThisComplexAction = _filter(
          this.currentStrategyRewardsHistory,
          { healthActionCd: this.healthActionCode }
        )
        return _sumBy(rewardsForThisComplexAction, 'amount')
      } else if (this.isCompoundHealthAction) {
        //  If the health action is a compound health action, we need to
        //  calculate the total amount of earnable rewards OR the total amount
        //  of earned rewards if (and only if) the container is completed. In
        //  both cases, the reward amounts of the appropriate component health
        //  actions are considered in the final total.
        return this.totalRewardsPossible
      }
      return _get(this.healthAction, 'incentiveAmount', 0)
    },
    attestationTitle() {
      return (
        _get(this.healthAction, 'attestationTitle') ||
        this.localize('health_actions.attestation.complete_call_to_action')
      )
    },
    attestationDescription() {
      return (
        _get(this.healthAction, 'attestationDescription') ||
        this.basicDescription
      )
    },
    attestationAction() {
      return (
        _get(this.healthAction, 'attestationAction') ||
        this.localize('health_actions.attestation.complete_form')
      )
    },
    basicDescription() {
      return this.localize('health_actions.details.complete_instructions', [
        this.adjustedTitle.toLowerCase(),
      ])
    },
    assignedMembers() {
      return _get(this.healthAction, 'memberHealthActions', [])
    },
    assignedNames() {
      return this.nameList(this.assignedMembers)
    },
    toDoMembers() {
      return _get(this.healthAction, 'toDoMembers', [])
    },
    toDoNames() {
      return this.nameList(this.toDoMembers)
    },
    completedMembers() {
      return _get(this.healthAction, 'completedMembers', [])
    },
    completedNames() {
      return this.nameList(this.completedMembers)
    },
    totalRewardsEarned() {
      const containerEarnedAmount = this.earnedAmountByHealthActionCd(
        this.healthActionCode
      )
      const componentEarnedAmount = _sumBy(
        _get(this.healthAction, 'compoundHealthActions', []),
        (ha) => this.earnedAmountByHealthActionCd(ha.code)
      )

      return containerEarnedAmount + componentEarnedAmount
    },
    showEarning() {
      return this.isComplete
        ? _get(this.healthAction, 'subscriberWasRewarded')
        : this.showDependents || !_get(this.currentRewardsSummary, 'maxedOut')
    },
    totalRewardsPossible() {
      const containerReward = _sumBy(
        this.healthAction.memberHealthActions,
        'maxEarnableIncentiveAmount'
      )

      const totalComponentRewards = _sumBy(
        _get(this.healthAction, 'compoundHealthActions'),
        (healthAction) =>
          _sumBy(healthAction.memberHealthActions, 'maxEarnableIncentiveAmount')
      )
      return containerReward + totalComponentRewards
    },
    earnableAmount() {
      return this.totalRewardsPossible - this.totalRewardsEarned
    },
    totalSteps() {
      if (this.isEducationBundle) {
        return 6
      } else if (this.isMrosSurvey) {
        return this.mrosQuestionnaires.length
      } else if (this.isCompoundHealthAction) {
        return this.compoundHealthActions.length
      } else {
        return 1
      }
    },
    completedSteps() {
      if (this.isHra) {
        return this.hraIsCompliant ? 1 : 0
      } else if (this.isQuestionnaireToDo) {
        return this.questionnaireToDoIsComplete ? 1 : 0
      } else if (this.isEducationBundle) {
        return this.historicalArticleCount
      } else if (this.isMrosSurvey) {
        return _sumBy(this.mrosQuestionnaires, (q) =>
          this.isQuestionnaireCompleted(q.type)
        )
      } else if (this.isCompoundHealthAction) {
        //TODO: this won't work with household view, should handle this during the refactor story
        return _sumBy(
          this.compoundHealthActions,
          ({ completedMembers }) => completedMembers.length
        )
      } else {
        return this.toDoMembers.length === 0 ? 1 : 0
      }
    },
    percentageComplete() {
      if (this.showDependents) {
        return Math.round(
          (this.completedMembers.length /
            (this.completedMembers.length + this.toDoMembers.length)) *
            100
        )
      } else {
        return Math.round((this.completedSteps / this.totalSteps) * 100)
      }
    },
    toDoSubtitle() {
      return this.localize('tracking.steps_completed', [
        this.completedSteps,
        this.totalSteps,
      ])
    },

    //compound health actions
    isCompoundHealthAction() {
      return !!this.healthAction.compoundHealthActions
    },
    compoundHealthActions() {
      return this.healthAction.compoundHealthActions
    },
    hasComplexChildrenWithRewards() {
      return (
        this.isCompoundHealthAction &&
        this.healthAction.compoundHealthActions.some(
          (childHealthAction) =>
            childHealthAction.healthActionTypeCd ===
              HEALTH_ACTION_TYPES.COMPLEX &&
            childHealthAction.incentiveAmount > 0
        )
      )
    },

    links() {
      const links = [
        {
          icon: 'Book',
          mobileIcon: 'book',
          title: this.localize('tracking.details.view_history'),
          webRoute: {
            name: 'health-actions-history',
            params: { id: this.healthActionId },
          },
          onTap: () =>
            this.$router.push('health-action-history', {
              props: { healthAction: this.healthAction },
            }),
          show:
            !this.isMrosSurvey &&
            !this.isEducationBundle &&
            !this.isHra &&
            !this.isQuestionnaireToDo,
        },
        {
          icon: 'Book',
          mobileIcon: 'book',
          title: this.localize('hra.details.view_results'),
          webRoute: `/health-actions/${this.healthActionId}/results`,
          onTap: () =>
            this.$router.push('questionnaire-results', {
              props: { action: this.healthAction },
            }),
          show:
            this.isQuestionnaireToDo &&
            this.questionnaireToDoHasPreviousCompletion,
        },
        {
          icon: 'Book',
          mobileIcon: 'book',
          title: this.localize('hra.details.view_results'),
          webRoute: '/health-actions/hra/results',
          onTap: () => this.$router.push('hra-results'),
          show:
            this.isHra &&
            this.hraHasPreviousCompletion &&
            this.questionnaireLastCompletedDate,
        },
        {
          icon: 'Notes',
          mobileIcon: 'icon-notes',
          title: this.localize('questionnaire.retake_survey'),
          webRoute: '/questionnaire/HRA',
          onTap: this.startHra,
          show: this.isHra && this.hraHasPreviousCompletion && !this.hraStarted,
        },
        {
          icon: 'Feedback',
          mobileIcon: 'question-circle',
          title: this.localize('hra.details.feedback'),
          webRoute: '/questionnaire/HRA_SAT',
          onTap: this.takeSurvey,
          show:
            this.isHra &&
            this.usesHraSatisfactionSurvey &&
            this.hraHasPreviousCompletion,
        },
      ]
      return _filter(links, 'show')
    },
  },
  methods: {
    ...mapActions('rewards', ['initRewards']),
    ...mapActions('healthActions', [
      'initHealthActions',
      'submitMemberAttestation',
    ]),
    ...mapActions('questionnaire', ['initQuestionnaires', 'start']),
    ...mapActions('tracking', ['initTracking']),
    ...mapActions('learn', ['initLearn']),
    formatDate(date) {
      return date ? format(date, 'MM/dd/yyyy') : ''
    },
    nameList(members, delimiter = ', ') {
      const total = members.length
      return total > 3
        ? this.$t('health_actions.home.you_plus_others', [total - 1])
        : flow(
            map(({ name }) => this.localize(name)),
            join(delimiter)
          )(members)
    },
    isQuestionnaireCompleted(type) {
      return isThisYear(this.questionnaireCompletedDate(type))
    },
    questionnaireCompletedDate(type) {
      return parseISO(_get(this.questionnaireByType(type), 'lastCompleted'))
    },
    isQuestionnaireAvailable(type) {
      return isAfter(
        new Date(),
        this.mrosQuestionnaires.find((q) => q.type === type).availableOn
      )
    },
    getQuestionnaireStatus(type) {
      return (
        _get(this.questionnaireByType(type), 'status') ||
        QUESTIONNAIRE_STATUS.NOT_STARTED
      )
    },
    memberToAttest(memberId = this.memberCoverageInfo.memberId) {
      return _find(this.assignedMembers, { memberId })
    },
    mostRecentAttestation(memberId = this.memberCoverageInfo.memberId) {
      const memberAttestations = _get(
        this.memberToAttest(memberId),
        'memberAttestations'
      )

      return new Date(
        Math.max(
          ...memberAttestations.map(
            ({ activityCompletionDate }) => new Date(activityCompletionDate)
          )
        )
      )
    },
    isInstanceBasedHealthAction() {
      return this.instanceStartDate
    },
    isWithinInstancePeriod(memberId = this.memberCoverageInfo.memberId) {
      return isWithinDateInterval(this.mostRecentAttestation(memberId), {
        start: this.instanceStartDate,
        end: this.instanceEndDate || addDays(new Date(), 1),
      })
    },
    memberAttestedThisPeriod(memberId = this.memberCoverageInfo.memberId) {
      if (this.isInstanceBasedHealthAction()) {
        return this.isWithinInstancePeriod(memberId)
      }
      const { startDate, endDate } = this.currentCompliancePeriod
      return (
        this.supportsMemberAttestation &&
        isWithinDateInterval(this.mostRecentAttestation(memberId), {
          start: startDate,
          end: endDate,
        })
      )
    },
    //compoundHealthActions
    lastCompoundCompletion(healthAction) {
      const lastCompletion = flow(
        getOr([], 'subscriberCompletions'),
        orderBy('completionDate', 'desc'),
        head,
        getOr('', 'completionDate')
      )(healthAction)
      return lastCompletion ? this.formatDate(parseISO(lastCompletion)) : ''
    },
  },
}
